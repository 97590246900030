@use "@carbon/styles/scss/theme" as *;
@use "@carbon/styles/scss/themes" as *;
@use "@carbon/styles/scss/spacing" as *;
@use "@carbon/styles/scss/motion" as *;
@use "@carbon/styles/scss/type";
@use "@carbon/type/scss/font-family" as *;
//@use "@carbon/styles/scss/utilities";

.np__container {
  position: fixed;
  z-index: 2;
  top: $spacing-09;
  right: 0;
  overflow: auto;
  min-width: 20rem;
  max-width: 22.75rem;
  min-height: 38.5rem;
  max-height: 38.5rem;
  background-color: $layer-01;
  color: $text-primary;
  border-color: $border-subtle-01;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  transition: transform $duration-fast-02 motion(standard);

  .np__header_container {
    position: sticky;
    z-index: 2;
    top: 0;
    padding: $spacing-03 $spacing-05 $spacing-05;
    border-bottom: 1px solid $border-subtle-01;
    background-color: $layer-01;
    .np__header_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .np__do_not_disturb_toggle .toggle__label_text {
      //@include utilities.visually-hidden;
    }
    .np__dismiss_button {
      color: $text-primary;
    }
    .np__header {
      @include type.type-style("heading-compact-01");

      margin: 0;
    }
  }
  .np__time_section_label {
    @include type.type-style("label-01");
    @include font-weight("semibold");

    position: sticky;
    z-index: 2;
    // stylelint-disable-next-line carbon/layout-token-use
    top: 4.8125rem;
    padding: $spacing-03 $spacing-05;
    background-color: $layer-01;
    color: $text-secondary;
  }
  .np__notification:hover,
  .np__notification:focus {
    background-color: $layer-accent-01;
    .np__dismiss-single-button {
      opacity: 1;
    }
  }
  .np__notification:focus {
    border-color: $focus;
    box-shadow: inset 0 0 0 2px $focus, inset 0 0 0 2px $background;
    outline: 0;
  }
  .np__notification {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 6.25rem;
    align-items: flex-start;
    padding: $spacing-05;
    border: 0;
    background-color: $background;
    cursor: pointer;
    text-align: left;
    transition: background-color $duration-moderate-02 motion(standard);
    .np__notification_title {
      margin-bottom: $spacing-02;
      color: $text-primary;
      @include font-weight("regular");
    }
    .np__notification_title.np__notification_title_unread {
      margin-bottom: $spacing-02;
      color: $text-primary;
      @include font-weight("semibold");
    }
    .np__notifications_link {
      @include type.type-style("label-01");
    }
    .np__notification_status_icon {
      min-width: 1rem;
      margin-right: $spacing-03;
      &.np__notification_status_icon_error {
        fill: $support-error;
      }
      &.np__notification_status_icon_success {
        fill: $support-success;
      }
      &.np__notification_status_icon_warning {
        fill: $support-warning;
      }
      &.np__notification_status_icon_informational {
        fill: $support-info;
      }
    }
    .np__notification_content {
      .np__notification_time_label {
        margin-bottom: $spacing-03;
      }
      .np__notification_time_label,
      .np__notification_description {
        @include type.type-style("label-01");

        color: $text-secondary;
      }
      .np__notification_description {
        &.np__notification_short_description {
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        &.np__notification_long_description {
          display: block;
          overflow: initial;
          -webkit-line-clamp: initial;
        }
      }
      .np__notification_read_more_button,
      .np__notification_read_less_button {
        @include type.type-style("label-01");

        min-width: 5.5rem;
        padding: 0;

        .btn__icon {
          // stylelint-disable-next-line carbon/motion-easing-use
          transition: transform $duration-moderate-02 ease;
          /* stylelint-disable-next-line max-nesting-depth */
          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }
        }
        &.np__notification_read_more_button {
          .btn__icon {
            transform: rotate(0deg);
          }
        }
        &.np__notification_read_less_button {
          .btn__icon {
            transform: rotate(180deg);
          }
        }
      }
    }
    .popover_container {
      position: initial;
    }
    .np__dismiss_single_button {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      min-width: 2rem;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: $text-primary;
      opacity: 0;

      /* stylelint-disable-next-line max-nesting-depth */
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }
  .np__notification_today:not(:first-of-type):before,
  .np__notification_yesterday:not(:first-of-type):before,
  .np__notification_previous:not(:first-of-type):before {
    position: absolute;
    top: 0;
    width: calc(100% - (2 * #{$spacing-05}));
    height: 1px;
    margin: 0 auto;
    background-color: $layer-02;
    content: "";
    transition: background-color $duration-moderate-02 motion(standard);
  }
  .np__notification_today:hover
    + .np__notification_today:not(:first-of-type):before,
  .np__notification_yesterday:hover
    + .np__notification_yesterday:not(:first-of-type):before,
  .np__notification_previous:hover
    + .np__notification_previous:not(:first-of-type):before {
    background-color: transparent;
  }
  .np__main_section-empty.np__main_section {
    display: flex;
    height: 100%;
    min-height: initial;
    align-items: center;
    justify-content: center;
    margin-top: $spacing-13;
    .subtext {
      @include type.type-style("body-compact-01");
    }
  }
  .np__main_section {
    min-height: 498px;
  }
  .np__bottom_actions {
    position: sticky;
    z-index: 2;
    bottom: 0;
    display: flex;
    height: 2.5rem;
    min-height: 2.5rem;
    align-items: center;
    border-top: 1px solid $layer-02;
    background-color: $background;
    .np__view_all_button {
      width: 100%;
      max-width: calc(100% - 2.5rem);
      height: 2.5rem;
      min-height: 2.5rem;
      border-right: 1px solid $layer-02;
      color: $text-primary;
    }
    .np__settings_button {
      display: flex;
      min-width: 2.5rem;
      height: 2.5rem;
      min-height: 2.5rem;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: $text-primary;
    }
    .np__settings-button .btn__icon {
      margin: 0;
    }
  }
}

.empty_state {
  text-align: center;
  padding-top: 3rem;
}

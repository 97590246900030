//
// flashing animation by changing the transparency of the SVG icon

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.networkStatus {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: auto;
  a {
    color: #00b894;
    text-decoration: none;
    font-size: 0.6rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg {
      margin-right: 0px;
      fill: #00b894;
    }
  }
  svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    animation: flash 5s infinite;
  }
}
.networkStatusLoading {
  @extend .networkStatus;
  a {
    color: #f1c40f;
    svg {
      fill: #f1c40f;
    }
  }
}
.networkStatusError {
  @extend .networkStatus;
  a {
    color: #e74c3c;
    svg {
      fill: #e74c3c;
    }
  }
}
